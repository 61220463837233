import React from "react"
import Img from "gatsby-image"
import CoreValues from "../content/core-values"

export default ({ image }) => (
  <>
    <h3 className="title has-text-weight-medium">Core Values</h3>
    <div className="static-page-container">
      <div>
        <Img fluid={image} />
      </div>
      <div>
        <p>{CoreValues.summary}</p>
        {CoreValues.values.map(value => (
          <div className="core-value mb-4">
            <h3 className="has-text-link my-4 has-text-weight-semibold">
              {value.title}
            </h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  </>
)
