import React from "react"
import Layout from "../components/layout"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
// import PeopleSection from "../components/people-section"
import BenefitsSection from "../components/benefits-section"
import JobsSection from "../components/jobs-section"
import CoreValues from "../components/core-values"
// import InstagramFeed from "../components/instagram-feed"
import AnchorLink from "react-anchor-link-smooth-scroll"
import SEO from "../components/seo"

const heroText = {
  title: [
    "We look for people who are passionate about changing the world and gravitate towards the ",
    <strong>risk-takers, dreamers, and leaders</strong>,
  ],
  subtitle:
    "Our team brings energy, enthusiasm, creativity, and whole-hearted hustle into everything we do",
}

export default ({ data }) => (
  <Layout>
    <SEO
      title="inPowered Careers - Join Silicon Valley's leading advertising AI startup"
      description="Join a team of risk-takers, dreamers, and leaders revolutionizing the advertising industry."
    />
    <ProductHero {...heroText}>
      <AnchorLink href="#jobs" offset="100">
        <button className="button is-cta-button is-rounded px-5">
          See open positions
        </button>
      </AnchorLink>
    </ProductHero>
    <div className="container px-4">
      <CoreValues image={data.thirdImage.childImageSharp.fluid} />
      {/* <PeopleSection /> */}
      <BenefitsSection />
      <JobsSection jobs={data.restApiJobs.data} />
      {/* <InstagramFeed /> */}
    </div>
    <AgenciesMarquee />
  </Layout>
)

export const pageQuery = graphql`
  query {
    thirdImage: file(relativePath: { eq: "about/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    restApiJobs {
      data {
        id
        title
        state
        country_id
        city
      }
    }
  }
`
