export default {
  summary:
    "Our core values drive everything we do: from what we build and who we hire, to how we work with clients and measure success. We are creating a company we are proud of, one that has the power to change the advertising industry as we know it.",
  values: [
    {
      title: "Engaging our people",
      description:
        "People are at the center of everything we do. We foster an environment of mutual trust and respect so that every person feels supported and empowered to achieve their fullest potential.",
    },
    {
      title: "Bringing Passion & Hustle into Everything We Do",
      description:
        "We bring passion for helping people become the best versions of themselves, each and everyday. We know our mission is lofty and that it will take heart and hustle, sweat and tears to get us there - and that's OK with us.",
    },
    {
      title: "Keeping It Real",
      description:
        "We believe that people and ideas flourish most in an environment of openness and transparency. By always being honest and accountable, we build trust and respect among ourselves and with our clients.",
    },
    {
      title: "Innovating",
      description:
        "We are constantly looking for ways to create game-changing solutions that bring us closer to achieving our mission. We dream big, move fast, take risks, celebrate wins, and learn from failures.",
    },
    {
      title: "Building Real Value for Customers",
      description:
        "We are laser-focused on helping people make informed decisions on things important to them and driving real ROI. That's why everything we do centers on driving maximum value for our customers and colleagues -- when they win, we win too.",
    },
    {
      title: "Having Fun",
      description:
        "We show up every day because we love the people we work with, and are dedicated to our mission of revolutionizing advertising. We may work hard, but make sure to have fun while doing so.",
    },
  ],
}
