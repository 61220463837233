import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(width: 48, height: 48) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const BenefitCard = ({ icon, description, title }) => (
  <div class="column">
    <div class="is-flex" style={{ alignItems: 'center' }}>
      <Img fixed={icon.localFile.childImageSharp.fixed} />
      <h3  class="title is-6 ml-2">{title}</h3>
    </div>
    <p class="mt-4">{description}</p>
  </div>
)

export default () => (
  <StaticQuery
      query={graphql`
        query {
          allWordpressAcfBenefits {
            nodes {
              acf {
                description
                title
                icon {
                  localFile {
                    ...squareImage
                  }
                }
              }
            }
          }
        }
      `}
      render = { (data) => (
        <section class=" container py-6">
          <h2 class="title  has-text-weight-medium">Benefits</h2>
          <div class="columns is-desktop mt-6">
            {data.allWordpressAcfBenefits.nodes.slice(0, 3).map(({ acf }) => (
              <BenefitCard {...acf} />
            ))}
          </div>

          <hr class="is-hidden-touch" style={{
            margin: '3rem 0'
          }}/>

          <div class="columns is-desktop">
           {data.allWordpressAcfBenefits.nodes.slice(3, 6).map(({ acf }) => (
              <BenefitCard {...acf} />
            ))}
          </div>
        </section>
    )}
  />
)
