import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

export default ({ jobs }) => {
  return (
    <div className="static-page-container my-6" id="jobs">
      <div>
        <h2 className="title has-text-weight-medium">Open Positions</h2>
      </div>
      <div>
        {jobs.map(job => (
          <Link className="job-row py-4" to={`/job/${job.id}`}>
            <div className="job-item">
              <div className="job-title">{job.title}</div>
              <div className="job-location">
                {job.city ? [job.city, job.state].join(", ") : job.country_id}
              </div>
            </div>
            <FontAwesomeIcon icon={faArrowRight} fixedWidth size="lg" />
          </Link>
        ))}
      </div>
    </div>
  )
}
